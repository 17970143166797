/* Utils */
.clearfix:after {
    content: " ";
    display: block;
    clear: both;
}

.card {
    background: var(--surface-card);
    border: 1px solid var(--surface-border);
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: var(--card-shadow);
    border-radius: $borderRadius;

    &:last-child {
        margin-bottom: 0;
    }
}

.p-toast {
    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 100px;
    }
}

.field-read-only {
    background-color: #f5f5f5; /* Color de fondo más oscuro para campos de solo lectura */
    color: #444; /* Color de texto para campos de solo lectura */
    border: none; /* Quita el borde si es necesario */
}

.checkbox-text {
    margin-right: 8px; /* Ajusta este valor según el espacio deseado */
}